.formula_builder {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tags_wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0px 90px;
	justify-content: flex-start;
}

.added_tags_wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 30px 30px;
	width: 80%;
	justify-content: center;
	margin-top: 30px;
	background-color: #74889c;
	border-radius: 4px;
}

.added_tags_text_wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 30px 30px;
	width: 80%;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 50px;
	background-color: #74889c;
	border-radius: 4px;
	color: white;
	font-size: 16px;
}

.tag {
	padding: 5px 15px;
	border-radius: 4px;
	color: white;
	text-transform: uppercase;
	cursor: pointer;
	margin: 5px 2px;
	text-align: center;
}

.tag.blue {
	background-color: #47b8ee;
}

.tag.dark_blue {
	background-color: #034577;
}

.tag.red {
	background-color: #d0464d;
}

.tag.green {
	background-color: #99ca4e;
}

.tag.dark_green {
	background-color: #2a850e;
}

.tag.orange {
	background-color: #fbad3c;
}

.tag_undo {
	margin-top: 30px;
}
.tag_undo i {
	font-size: 30px;
}
